'use client'

import Box from '@mui/material/Box'
import React from 'react'
import List from '@mui/material/List'
import CurrentStandingsTable from '../standings/CurrentStandingsTable'
import { useGetLeagueStandings } from '@/lib/hooks/useStanding'
import { useConstantsContext } from '@/lib/contexts/ConstantsContext'

const CurrentStandingsWidget = ({ selectedConference }: { selectedConference: Enum.Conference }): JSX.Element => {
    const { scheduleYear } = useConstantsContext()
    const { data: standings, isLoading: isLoadingCurrentStandings } = useGetLeagueStandings(
        scheduleYear.toString(),
        undefined,
        'NBA'
    )

    return (
        <Box>
            <List sx={{ paddingY: 0 }}>
                <Box>
                    <CurrentStandingsTable
                        leagueName="NBA"
                        seasonType="NBA_REGULAR_SEASON"
                        isLoading={isLoadingCurrentStandings}
                        standings={standings?.filter((d) => d.conference === selectedConference)}
                        isWidget
                    />
                </Box>
            </List>
        </Box>
    )
}

export default CurrentStandingsWidget
