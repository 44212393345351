import React, { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import ListSubheader from '@mui/material/ListSubheader'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import { Button } from '@mui/material'
import {
    type ScoutAssignmentQueryParams,
    useQueryAssignmentWidget,
    useQueryScoutAssignments,
} from '../../lib/hooks/useScoutAssignments'
import Link from '../Link'
import { formatDateString, isToday } from '../../lib/utils/formatters'
import CalendarEventDialog from '../scout-assignments/CalendarEventDialog'

type AssignmentsByDate = {
    date: string
    isToday: boolean
    assignments: (DTO.AssignmentWidget | undefined)[]
}

const AssignmentsWidget = (): JSX.Element => {
    const [currentAssignment, setCurrentAssignment] = useState<string | undefined>(undefined)
    const [currentGame, setCurrentGame] = useState<string | undefined>(undefined)
    const [currentScout, setCurrentScout] = useState<string | undefined>(undefined)

    const { data: assignmentData, isLoading: isAssignmentsLoading } = useQueryAssignmentWidget()

    const { data: selectedAssignment } = useQueryScoutAssignments(
        {
            assignmentIds: currentAssignment && !currentGame ? [currentAssignment] : undefined,
            gameIds: currentGame ? [currentGame] : undefined,
            scoutIds: currentScout && !currentGame ? [currentScout] : undefined,
        },
        { enabled: !!(currentAssignment || currentGame) }
    )
    const filterParams: ScoutAssignmentQueryParams = {}

    const assignmentsByDateArr = useMemo<AssignmentsByDate[] | undefined>(() => {
        let arr: AssignmentsByDate[] | undefined
        if (assignmentData) {
            const assignmentsByDate = assignmentData.reduce<Record<string, DTO.AssignmentWidget[]>>(
                (groups, assignment) => {
                    const dateStr = assignment.date
                    if (dateStr in groups) {
                        groups[dateStr].push(assignment)
                        return groups
                    }
                    return { ...groups, [dateStr]: [assignment] }
                },
                {}
            )

            arr = Object.entries(assignmentsByDate)
                .sort((a, b) => (dayjs(a[0]).isAfter(b[0]) ? 1 : -1))
                .map(([date, assignments]) => ({
                    date: formatDateString(date, 'dddd, MMM D', 'local'),
                    isToday: isToday(date),
                    assignments: assignments.sort(
                        (a, b) =>
                            a.level.localeCompare(b.level) ||
                            (a.scoutName || '').localeCompare(b.scoutName || '') ||
                            (dayjs(a.date).isBefore(b.date) ? -1 : 1)
                    ),
                }))
        } else if (isAssignmentsLoading) {
            // mock out array for loading skeleton
            arr = new Array(2)
                .fill(undefined)
                .map((_, idx) => ({ date: idx.toString(), assignments: new Array(5).fill(undefined), isToday: false }))
        }
        return arr
    }, [assignmentData, isAssignmentsLoading])

    return (
        <>
            <Stack>
                {assignmentsByDateArr?.map((row, index) => (
                    <Box key={row.date}>
                        <ListSubheader
                            sx={{
                                color: 'text.primary',
                                padding: 0,
                                lineHeight: '36px',
                                backgroundColor: 'grey.100',
                            }}
                        >
                            <Box
                                sx={{
                                    borderStyle: 'solid',
                                    borderColor: 'divider',
                                    borderLeft: '1px',
                                    borderRight: '1px',
                                    borderTop: index === 0 ? '1px' : undefined,
                                    paddingLeft: 2,
                                }}
                            >
                                {isAssignmentsLoading && <Skeleton width="33%" />}
                                {!isAssignmentsLoading &&
                                    (row.isToday ? (
                                        <Chip label="Today" color="primary" size="small" sx={{ fontSize: '14px' }} />
                                    ) : (
                                        row.date
                                    ))}
                            </Box>
                        </ListSubheader>

                        <Table size="small">
                            <TableBody>
                                {row.assignments.map((data, idx) => (
                                    <TableRow
                                        key={data ? `${data.assignmentId}_${data.scoutId || ''}` : idx}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell sx={{ paddingLeft: 3, width: '175px' }}>
                                            {data?.scoutUrlSlug ? (
                                                <Link href={`/users/${data.scoutUrlSlug}/feed`} underline="none">
                                                    {data.scoutName}
                                                </Link>
                                            ) : (
                                                <Skeleton width={175} />
                                            )}
                                        </TableCell>
                                        <TableCell sx={{ color: 'text.secondary', textAlign: 'right' }}>
                                            <Button
                                                variant="text"
                                                sx={{
                                                    fontWeight: 400,
                                                    marginY: '-5px',
                                                    marginX: '-8px',
                                                    textTransform: 'inherit',
                                                }}
                                                onClick={() => {
                                                    if (data) {
                                                        setCurrentAssignment(data.assignmentId)
                                                        if (data.gameId) {
                                                            setCurrentGame(data.gameId)
                                                        }
                                                        if (data.scoutId) {
                                                            setCurrentScout(data.scoutId)
                                                        }
                                                    }
                                                }}
                                            >
                                                {data ? data.title : <Skeleton width={100} />}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                ))}
                {assignmentsByDateArr?.length === 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 100,
                            width: '100%',
                        }}
                    >
                        <Typography variant="h6" color="text.secondary">
                            No Current Assignments
                        </Typography>
                    </Box>
                )}
            </Stack>
            <CalendarEventDialog
                open={!!currentAssignment}
                setOpen={() => {
                    setCurrentAssignment(undefined)
                    setCurrentGame(undefined)
                    setCurrentScout(undefined)
                }}
                event={selectedAssignment ? selectedAssignment[0].extendedProps : undefined}
                filterParams={filterParams}
                widget
            />
        </>
    )
}

export default AssignmentsWidget
