import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { FormControl, MenuItem, Paper, Select } from '@mui/material'
import { useSession } from 'next-auth/react'
import PermissionFeature from '../wrappers/PermissionFeature'
import CurrentStandingsWidget from './CurrentStandingsWidget'
import { a11yProps, TabPanel } from './AssignmentScheduleWidget'
import LeagueScheduleWidget from './LeagueScheduleWidget'

const ScheduleStandingsWidget = (): JSX.Element => {
    const { data: session } = useSession()

    const hasStandingPerms = session?.roles.featurePermissions['league-standings']

    const [tab, setTab] = useState(hasStandingPerms ? 0 : 1)

    const handleTabChange = (_e: React.SyntheticEvent, newTab: number) => {
        setTab(newTab)
    }

    const [selectedConference, setSelectedConference] = useState<Enum.Conference>('East')

    return (
        <PermissionFeature type="assignment-schedule">
            <Paper variant="outlined" sx={{ minWidth: '100%', minHeight: 400 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}
                >
                    <Tabs value={tab} onChange={handleTabChange}>
                        {hasStandingPerms && <Tab label="Standings" {...a11yProps(0)} />}
                        <Tab label="Schedule" {...a11yProps(1)} />
                    </Tabs>
                    {tab === 0 && (
                        <Box sx={{ paddingX: 1, display: 'flex', justifyContent: 'flex-end' }}>
                            <Box sx={{ maxWidth: '100px' }}>
                                <FormControl sx={{ display: 'flex', width: '100%' }}>
                                    <Select
                                        onChange={(e) => {
                                            setSelectedConference(e.target.value as Enum.Conference)
                                        }}
                                        value={selectedConference}
                                        sx={{
                                            height: '30px',
                                            padding: 0,
                                        }}
                                    >
                                        {['East', 'West'].map((o) => (
                                            <MenuItem key={o} value={o} sx={{ fontSize: '14px' }}>
                                                {o}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    )}
                </Box>
                <Box sx={{ maxHeight: '652px', overflow: 'auto' }}>
                    <TabPanel value={tab} index={0}>
                        {hasStandingPerms && <CurrentStandingsWidget selectedConference={selectedConference} />}
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <LeagueScheduleWidget />
                    </TabPanel>
                </Box>
            </Paper>
        </PermissionFeature>
    )
}

export default ScheduleStandingsWidget
