'use client'

import React from 'react'
import Feed from '@/components/feed/Feed'
import { useFeedQueryParams, useQueryAllPosts } from '@/lib/hooks'

const HomePageFeed = (): JSX.Element => {
    const [type, setType, query, hasQueryParams] = useFeedQueryParams()
    const { data: posts, status, fetchNextPage, hasNextPage } = useQueryAllPosts(type, query)

    return (
        <Feed<true>
            data={posts}
            isLoading={status === 'loading'}
            fetchNextPage={fetchNextPage}
            hasNextPage={!!hasNextPage}
            type={type}
            setType={setType}
            includeFollowing
            hasQueryParams={hasQueryParams}
        />
    )
}

export default HomePageFeed
