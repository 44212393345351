'use client'

import type { WatchListWidgetPlayerViewProps } from './WatchListWidgetPlayerView'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ListSubheader from '@mui/material/ListSubheader'
import Skeleton from '@mui/material/Skeleton'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@mui/icons-material/Settings'
import { useSession } from 'next-auth/react'
import Paper from '@mui/material/Paper'
import Slide from '@mui/material/Slide'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import useUserWatchList, { useUserWatchListItems } from '../../lib/hooks/useUserWatchList'
import { useBreakPoints } from '../../lib/hooks'
import { MAX_DISPLAY_HEADSHOTS } from '../boards/Board'
import PageName from '../PageName'
import TableLoadingSkeleton from '../Table/LoadingSkeleton'
import AddWatchListItemDialog from './AddWatchListItemDialog'
import WatchListWidgetPlayerView, { PlayerPrevGame } from './WatchListWidgetPlayerView'

const WatchListLoadingSkeleton = () => (
    <Grid container paddingTop={1} paddingX={2}>
        <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" gap={1}>
                    <Skeleton variant="circular" width={38} height={38} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Skeleton variant="rectangular" width={150} height={12} />
                        <Skeleton variant="rectangular" width={150} height={12} />
                    </Box>
                </Box>
                <Box>
                    <Skeleton variant="rectangular" width={100} height={32} />
                </Box>
            </Box>
        </Grid>
        <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center">
            <TableLoadingSkeleton
                config={{
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    fields: Array(9)
                        .fill(undefined)
                        .map((_, idx) => ({ skeletonStyle: { xs: 36 }, key: idx, label: '' })),
                    loadingSkeleton: { numOfRows: 1, height: 76 },
                }}
            />
        </Grid>
        <Grid item xs={12} display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between" position="relative" top={4}>
                <Skeleton variant="rectangular" width={118} height={16} />
                <Skeleton variant="rectangular" width={118} height={16} />
            </Box>
        </Grid>
        <Divider sx={{ marginTop: 2, width: '100%' }} />
    </Grid>
)

export const mapUserWatchListInfoToWatchListPrevGameInfo = (p: DTO.UserWatchListInfo): DTO.WatchListPrevGameInfo => ({
    game: {
        gameId: p.game.gameId,
        date: p.game.gameDate,
        result: p.prevGameInfo.gameResult,
        league: p.prevGameInfo.league,
    },
    isHomeTeam: p.prevGameInfo.isHomeTeam,
    opponent: {
        urlSlug: p.prevGameInfo.opponentUrlSlug,
        abbr: p.prevGameInfo.opponentAbbr || '',
        name: '',
        teamId: '',
    },
    team: {
        urlSlug: p.prevGameInfo.urlSlug ? p.prevGameInfo.urlSlug : '',
        abbr: p.prevGameInfo.abbr || '',
        name: '',
        teamId: '',
    },
    homeTeamScore: p.prevGameInfo.homeTeamScore,
    awayTeamScore: p.prevGameInfo.awayTeamScore,
    boxscore: p.boxscore,
    scoutingReports: [],
})

const WatchListWidget = (): JSX.Element => {
    const { isMobile, isTablet, isLandscape, isDesktop } = useBreakPoints()
    const [isAddItemDialogOpen, setIsAddItemDialogOpen] = useState(false)
    const [isPlayerViewActive, setIsPlayerViewActive] = useState(false)
    const [selectedPlayerInfo, setSelectedPlayerInfo] = useState<
        WatchListWidgetPlayerViewProps['playerInfo'] | undefined
    >(undefined)
    const containerRef = useRef<HTMLDivElement | null>(null)
    const scrollRef = useRef<number | null>(null)
    const listRef = useRef<HTMLUListElement>(null)
    const { data: session } = useSession()
    const { data: initWatchListValues } = useUserWatchListItems()
    const { data: watchListInfo, isLoading: isWatchListInfoLoading } = useUserWatchList()

    const boardLevels = useMemo(() => {
        const levels: Enum.BoardLevels[] = []
        if (session?.roles.contentPermissions['pro-boards']) {
            levels.push('Pro')
        }
        if (session?.roles.contentPermissions['amateur-boards']) {
            levels.push('Amateur')
        }
        return levels
    }, [session])

    useEffect(() => {
        const listRefTarget = listRef.current
        if (scrollRef.current && listRefTarget) {
            listRefTarget.scrollTop = scrollRef.current
        }
    }, [isPlayerViewActive])

    const handlePlayerClick = (playerInfo: WatchListWidgetPlayerViewProps['playerInfo']) => {
        setIsPlayerViewActive(true)
        setSelectedPlayerInfo(playerInfo)
    }
    const handleBackIconPress = () => setIsPlayerViewActive((prev) => !prev)

    const onScroll = (e: React.UIEvent) => {
        const target = e.target as HTMLUListElement
        scrollRef.current = target.scrollTop
    }

    return (
        <Box>
            {(isMobile || isTablet) && (
                <>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
                        <Box width="50%">
                            <PageName text="Watch List" />
                        </Box>
                        <Box width="50%" display="flex" flexDirection="row-reverse">
                            <IconButton sx={{ right: 0 }} onClick={() => setIsAddItemDialogOpen(true)}>
                                <SettingsIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </Box>
                    <AddWatchListItemDialog
                        isOpen={isAddItemDialogOpen}
                        boardLevels={boardLevels}
                        initialValues={initWatchListValues}
                        handleDialogClose={() => setIsAddItemDialogOpen(false)}
                    />
                </>
            )}
            <Paper
                variant="outlined"
                sx={{ minWidth: '100%', height: { xs: '80vh', md: '464px' }, overflow: 'auto' }}
                ref={containerRef}
            >
                <Slide
                    direction="right"
                    in={!isPlayerViewActive}
                    container={containerRef.current}
                    mountOnEnter
                    unmountOnExit
                    appear={false}
                >
                    <List sx={{ height: '100%', overflow: 'auto', paddingTop: 0 }} onScroll={onScroll} ref={listRef}>
                        {(isLandscape || isDesktop) && (
                            <ListSubheader
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderTopLeftRadius: 1,
                                    borderTopRightRadius: 1,
                                    padding: 1,
                                    borderBottom: '1px solid',
                                    borderColor: 'divider',
                                    zIndex: 2,
                                }}
                            >
                                <Box sx={{ display: 'flex', width: '50%', paddingLeft: 1 }}>
                                    <Typography variant="button">WATCH LIST</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row-reverse', width: '50%' }}>
                                    <IconButton sx={{ right: 0 }} onClick={() => setIsAddItemDialogOpen(true)}>
                                        <SettingsIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                                <AddWatchListItemDialog
                                    isOpen={isAddItemDialogOpen}
                                    boardLevels={boardLevels}
                                    initialValues={initWatchListValues}
                                    handleDialogClose={() => setIsAddItemDialogOpen(false)}
                                />
                            </ListSubheader>
                        )}
                        {isWatchListInfoLoading && (
                            <>
                                <WatchListLoadingSkeleton />
                                <WatchListLoadingSkeleton />
                                <WatchListLoadingSkeleton />
                                <WatchListLoadingSkeleton />
                            </>
                        )}
                        {initWatchListValues &&
                            initWatchListValues.boards.length < 1 &&
                            initWatchListValues.players.length < 1 && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: '100%',
                                        paddingTop: 4,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginBottom: 2,
                                            width: '100%',
                                        }}
                                    >
                                        <Typography variant="h6" color="text.secondary">
                                            No Players In Watch List
                                        </Typography>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            View previous game stats for selected list of players
                                        </Typography>
                                    </Box>
                                    <Button variant="contained" onClick={() => setIsAddItemDialogOpen(true)}>
                                        Add Players
                                    </Button>
                                </Box>
                            )}

                        {!!watchListInfo &&
                            watchListInfo.map((d: DTO.UserWatchListInfo) => (
                                <PlayerPrevGame
                                    playerInfo={d}
                                    playerView={false}
                                    showHeadshot={watchListInfo.length < MAX_DISPLAY_HEADSHOTS}
                                    prevGameInfo={mapUserWatchListInfoToWatchListPrevGameInfo(d)}
                                    handlePlayerClick={handlePlayerClick}
                                    scoutingReports={d.scoutingReports}
                                    isDesktop={isDesktop}
                                    key={d.playerId}
                                />
                            ))}
                        {watchListInfo &&
                            watchListInfo.length < 1 &&
                            initWatchListValues &&
                            (initWatchListValues.boards.length > 0 || initWatchListValues.players.length > 0) && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 100,
                                        width: '100%',
                                    }}
                                >
                                    <Typography variant="h6" color="text.secondary">
                                        No Games Played In The Last 30 Days
                                    </Typography>
                                </Box>
                            )}
                    </List>
                </Slide>

                <Slide
                    direction="left"
                    in={isPlayerViewActive}
                    container={containerRef.current}
                    mountOnEnter
                    unmountOnExit
                    appear={false}
                >
                    <Box sx={{ height: '100%', overflow: 'auto', paddingTop: 0 }}>
                        {selectedPlayerInfo && (
                            <WatchListWidgetPlayerView
                                handleBackIconPress={handleBackIconPress}
                                playerInfo={selectedPlayerInfo}
                            />
                        )}
                    </Box>
                </Slide>
            </Paper>
        </Box>
    )
}

export default WatchListWidget
