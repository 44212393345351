export const ncaaConferenes: Enum.NCAAConference[] = [
    'America East',
    'American Athletic',
    'Atlantic 10',
    'Atlantic Coast',
    'Atlantic Sun',
    'Big 12',
    'Big East',
    'Big Sky',
    'Big South',
    'Big Ten',
    'Big West',
    'CAA',
    'Conference USA',
    'Horizon',
    'Ivy',
    'Metro Atlantic Athletic',
    'Mid Eastern Athletic',
    'Mid-American',
    'Missouri Valley',
    'Mountain West',
    'Northeast',
    'Ohio Valley',
    'Patriot League',
    'Southeastern',
    'Southern',
    'Southland',
    'Southwestern Athletic',
    'Summit League',
    'Sun Belt',
    'West Coast',
    'Western Athletic',
]

export const powerConferences: Enum.NCAAConference[] = [
    'Atlantic Coast',
    'Big 12',
    'Big Ten',
    'Southeastern',
    'Big East',
]

export const conferenceAbbr: Record<Enum.NCAAConference, string> = {
    'America East': 'AE',
    'American Athletic': 'AAC',
    'Atlantic 10': 'A10',
    'Atlantic Coast': 'ACC',
    'Atlantic Sun': 'AS',
    'Big 12': 'Big 12',
    'Big East': 'Big East',
    'Big Sky': 'Big Sky',
    'Big South': 'Big South',
    'Big Ten': 'Big 10',
    'Big West': 'Big West',
    CAA: 'CAA',
    'Conference USA': 'CUSA',
    Horizon: 'Horizon',
    Ivy: 'Ivy',
    'Metro Atlantic Athletic': 'MAAC',
    'Mid Eastern Athletic': 'MEAC',
    'Mid-American': 'MAC',
    'Missouri Valley': 'MVC',
    'Mountain West': 'MWC',
    Northeast: 'NE',
    'Ohio Valley': 'OVC',
    'Patriot League': 'Patriot',
    Southeastern: 'SEC',
    Southern: 'Southern',
    Southland: 'Southland',
    'Southwestern Athletic': 'SWAC',
    'Summit League': 'Summit',
    'Sun Belt': 'Sun Belt',
    'West Coast': 'WCC',
    'Western Athletic': 'WAC',
}
