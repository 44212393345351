'use client'

import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Paper, Typography } from '@mui/material'
import { useSession } from 'next-auth/react'
import Badge from '@mui/material/Badge'
import PermissionFeature from '../wrappers/PermissionFeature'
import { useQueryIncompleteReportsSummary } from '../../lib/hooks'
import AssignmentsWidget from './AssignmentsWidget'
import IncompleteReportsWidget from './IncompleteReportsWidget'
import { getIncompleteAssignmentsQueryParams } from '@/lib/hooks/useUserFollowing'

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

export const TabPanel = (props: TabPanelProps): JSX.Element => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ height: '100%', width: '100%' }}
            {...other}
        >
            {value === index && (
                <Box sx={{ height: '100%', width: '100%' }}>
                    <Typography component="div" sx={{ height: '100%', width: '100%' }}>
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    )
}

export const a11yProps = (index: number): { id: string; 'aria-controls': string } => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
})

const MultiWidgetContainer = (): JSX.Element => {
    const { data: session } = useSession()
    const isAssignmentManager = !!session?.roles.featurePermissions['assignment-schedule-management']
    const hasAssignmentContentPermissions = !!(
        session?.roles.contentPermissions['pro-assignments'] || session?.roles.contentPermissions['amateur-assignments']
    )

    const params = getIncompleteAssignmentsQueryParams(session)
    const { data: reports, isLoading: isReportsLoading } = useQueryIncompleteReportsSummary(params, {
        enabled: hasAssignmentContentPermissions,
    })

    const [value, setValue] = React.useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <PermissionFeature type="assignment-schedule">
            <Paper variant="outlined" sx={{ minWidth: '100%', height: '400px' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Assignments" {...a11yProps(0)} />
                        {hasAssignmentContentPermissions && (
                            <Tab
                                label="Incomplete Reports"
                                sx={{
                                    minHeight: '48px',
                                    overflow: 'visible',
                                }}
                                iconPosition="end"
                                icon={
                                    !isReportsLoading && reports && reports.length > 0 ? (
                                        <Badge
                                            sx={{
                                                position: 'absolute',
                                                right: -3,
                                                top: 24,
                                                '& .MuiBadge-badge': {
                                                    color: 'common.white',
                                                    backgroundColor: 'error.light',
                                                },
                                            }}
                                            badgeContent={reports.length}
                                            max={99}
                                        />
                                    ) : undefined
                                }
                                {...a11yProps(1)}
                            />
                        )}
                    </Tabs>
                </Box>
                <Box sx={{ minWidth: '100%', height: '85%', overflow: 'auto' }}>
                    <TabPanel value={value} index={0}>
                        <AssignmentsWidget />
                    </TabPanel>
                    {hasAssignmentContentPermissions && (
                        <TabPanel value={value} index={1}>
                            <IncompleteReportsWidget
                                incompleteReports={reports}
                                isLoading={isReportsLoading}
                                isAssignmentManager={isAssignmentManager}
                            />
                        </TabPanel>
                    )}
                </Box>
            </Paper>
        </PermissionFeature>
    )
}

export default MultiWidgetContainer
