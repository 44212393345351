'use client'

import React from 'react'
import Grid from '@mui/material/Grid'
import { useSession } from 'next-auth/react'
import WidgetContainer from '@/components/widgets/WidgetContainer'
import AssignmentScheduleWidget from '@/components/widgets/AssignmentScheduleWidget'
import WatchListWidget from '@/components/widgets/WatchListWidget'
import InjuryWidget from '@/components/widgets/InjuryWidget'
import ScheduleStandingsWidget from '@/components/widgets/ScheduleStandingsWidget'
import PrefetchUserFollowing from '@/components/feed/PrefetchUserFollowing'

const Index = (): JSX.Element => {
    const { data: session } = useSession()
    return (
        <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} lg={8}>
                <PrefetchUserFollowing />
            </Grid>
            <WidgetContainer>
                <AssignmentScheduleWidget />
                <WatchListWidget />
                <ScheduleStandingsWidget />
                {session?.roles.contentPermissions['injury-history'] && <InjuryWidget league="NBA" />}
            </WidgetContainer>
        </Grid>
    )
}

export default Index
