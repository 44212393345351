'use client'

import React from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { useBreakPoints } from '../../lib/hooks'

type WidgetContainerProps = {
    children: React.ReactNode | React.ReactNode[]
}

const WidgetContainer = ({ children }: WidgetContainerProps): JSX.Element | null => {
    const { isDesktop } = useBreakPoints()
    if (!isDesktop) return null

    return (
        <Grid container item lg={4}>
            <Stack sx={{ marginTop: '38px', top: 108, width: '100%' }} spacing={1}>
                {children}
            </Stack>
        </Grid>
    )
}

export default WidgetContainer
