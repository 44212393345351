import type TableConfig from '../../lib/types/tableConfigTypes'
import type { TableConfigField } from '../../lib/types/tableConfigTypes'
import type { ScoutAssignmentQueryParams } from '@/lib/hooks/useScoutAssignments'
import React, { useState, useCallback } from 'react'
import Box from '@mui/material/Box'
import { Badge, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Button from '@mui/material/Button'
import dayjs from 'dayjs'
import { formatDateString } from '../../lib/utils/formatters'
import { useQueryScoutAssignments } from '../../lib/hooks'
import Table from '../Table'
import Link from '../Link'
import CalendarEventDialog from '../scout-assignments/CalendarEventDialog'

const firstInitialLastName = (name: string) => {
    const [firstName, lastName] = name.split(' ')
    return `${firstName[0]}. ${lastName}`
}

type GroupedIncompleteReports = {
    assignmentId: string
    scoutName: string
    scoutSlug: string
    scoutId: string
    eventTitle: string
    eventDate: Date
    eventDateString: string
    gameId: string
    eventType: Enum.ScoutingReportSettingType
    eventLevel: Enum.BoardLevels
    numIncompleteReports: number
}

const tableConfig = (
    isManager: boolean,
    setCurrentAssignment: (e: string | undefined, s: string | undefined, g: string | undefined) => void
): TableConfig<GroupedIncompleteReports> => {
    let fields: TableConfigField<GroupedIncompleteReports>[] = [
        {
            key: 'assignment',
            label: 'Assignment',
            numeric: !!isManager,
            skeletonStyle: 100,
            format: { height: '40px' },
            select: (row) => (
                <Box>
                    <Button
                        variant="text"
                        sx={{
                            fontWeight: 400,
                            marginY: '-5px',
                            marginX: '-8px',
                            textTransform: 'inherit',
                        }}
                        onClick={() => setCurrentAssignment(row.assignmentId, row.scoutId, row.gameId)}
                    >
                        {row.eventTitle} ({row.eventDateString})
                    </Button>
                    <Badge
                        sx={{
                            '& .MuiBadge-badge': {
                                color: 'common.white',
                                backgroundColor: 'error.light',
                            },
                            marginLeft: 2,
                        }}
                        badgeContent={row.numIncompleteReports}
                    />
                </Box>
            ),
        },
    ]

    const scoutField: TableConfigField<GroupedIncompleteReports> = {
        key: 'scout',
        label: 'Scout',
        select: (row) => (
            <Link href={`/users/${row.scoutSlug}/feed`} underline="none">
                {firstInitialLastName(row.scoutName)}
            </Link>
        ),
        format: { width: '150px' },
        skeletonStyle: 100,
    }

    if (isManager) {
        fields = [scoutField, ...fields]
    }

    return {
        initialSort: [
            { order: 'desc', orderBy: 'eventDate' },
            { order: 'asc', orderBy: 'scoutName' },
        ],
        loadingSkeleton: {
            height: 330,
            numOfRows: 12,
        },
        fields,
    }
}

type IncompleteReportsWidgetProps = {
    incompleteReports: DTO.IncompleteReportSummary[] | undefined
    isLoading: boolean
    isAssignmentManager: boolean
}
const IncompleteReportsWidget = ({
    incompleteReports,
    isLoading,
    isAssignmentManager,
}: IncompleteReportsWidgetProps): JSX.Element => {
    const [currentAssignment, setCurrentAssignment] = useState<string | undefined>(undefined)
    const [currentGame, setCurrentGame] = useState<string | undefined>(undefined)
    const [currentScout, setCurrentScout] = useState<string | undefined>(undefined)
    const handleSetCurrentAssignment = useCallback(
        (e: string | undefined, s: string | undefined, g: string | undefined) => {
            setCurrentAssignment(e)
            setCurrentScout(s)
            setCurrentGame(g)
        },
        [setCurrentAssignment]
    )

    const { data: selectedAssignment } = useQueryScoutAssignments(
        {
            assignmentIds: currentAssignment && !currentGame ? [currentAssignment] : undefined,
            gameIds: currentGame ? [currentGame] : undefined,
            scoutIds: currentScout ? [currentScout] : undefined,
        },
        { enabled: !!(currentAssignment || currentGame) }
    )

    const groupedIncompleteReports: GroupedIncompleteReports[] =
        incompleteReports?.map((assignments) => ({
            assignmentId: assignments.assignmentId,
            scoutName: assignments.scoutName,
            scoutSlug: assignments.scoutUrl,
            scoutId: assignments.scoutId,
            eventTitle: assignments.eventTitle,
            eventType: assignments.eventType,
            eventLevel: assignments.level,
            gameId: assignments.gameId,
            eventDate:
                assignments.eventType === 'Game' && assignments.gameTimeUtc
                    ? dayjs(assignments.gameTimeUtc).toDate()
                    : dayjs(assignments.eventDate).toDate(),
            eventDateString:
                assignments.eventType === 'Game' && assignments.gameTimeUtc
                    ? formatDateString(assignments.gameTimeUtc, 'M/D', 'eastern')
                    : formatDateString(assignments.eventDate, 'M/D', 'utc'),
            numIncompleteReports: assignments.numIncompleteReports,
        })) || []

    if (incompleteReports?.length === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 100,
                    width: '100%',
                    gap: 1,
                }}
            >
                <Typography variant="h6" color="text.secondary">
                    All Reports Completed
                </Typography>
                <CheckCircleIcon color="success" />
            </Box>
        )
    }
    return (
        <>
            <Table<GroupedIncompleteReports>
                rows={groupedIncompleteReports}
                config={tableConfig(!!isAssignmentManager, handleSetCurrentAssignment)}
                isLoading={isLoading}
                getRowKey={(row) => `${row.assignmentId}_${row.scoutId}`}
                hideSortIcon
            />
            <CalendarEventDialog
                open={!!currentAssignment}
                setOpen={() => handleSetCurrentAssignment(undefined, undefined, undefined)}
                event={selectedAssignment ? selectedAssignment[0].extendedProps : undefined}
                filterParams={{} as ScoutAssignmentQueryParams}
                widget
            />
        </>
    )
}

export default IncompleteReportsWidget
