'use client'

import React from 'react'
import HomePageFeed from './HomePageFeed'
import Feed from '@/components/feed/Feed'
import { useUserFollowing } from '@/lib/hooks'

const PrefetchUserFollowing = (): JSX.Element => {
    const { isLoading } = useUserFollowing()
    if (isLoading)
        // display temporary Feed loading skeleton
        return (
            <Feed<true>
                data={undefined}
                isLoading
                fetchNextPage={() => Promise.reject()}
                hasNextPage={false}
                type=""
                setType={() => {}}
                includeFollowing
                hasQueryParams={false}
            />
        )

    return <HomePageFeed />
}

export default PrefetchUserFollowing
