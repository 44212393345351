import type { SelectChangeEvent } from '@mui/material/Select'
import React from 'react'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { gameTypeDict } from '@/lib/utils/standings'

type SelectGameTypeProps = {
    selectedTypes: Enum.GameType[]
    onGameTypeChange: (e: SelectChangeEvent<Enum.GameType[]>) => void
    league: Enum.League
}

type LeagueGameType = { value: Enum.GameType; label: string }
const nbaGameTypes: LeagueGameType[] = [
    { value: 'PRESEASON', label: gameTypeDict.PRESEASON },
    { value: 'REGULAR', label: gameTypeDict.REGULAR },
    { value: 'PLAY_IN', label: gameTypeDict.PLAY_IN },
    { value: 'PLAYOFF', label: gameTypeDict.PLAYOFF },
    { value: 'IN_SEASON', label: gameTypeDict.IN_SEASON },
]
const glgGameTypes: LeagueGameType[] = [
    { value: 'REGULAR', label: gameTypeDict.REGULAR },
    { value: 'SHOWCASE', label: gameTypeDict.SHOWCASE },
    { value: 'PLAYOFF', label: gameTypeDict.PLAYOFF },
]
const amGameTypes: LeagueGameType[] = [
    { value: 'REGULAR', label: gameTypeDict.REGULAR },
    { value: 'PLAY_IN', label: 'Conference' },
    { value: 'PLAYOFF', label: gameTypeDict.PLAYOFF },
]
export const getLeagueGameTypes = (league: DTO.Team['leagueId']): LeagueGameType[] =>
    league === 'NBA' ? nbaGameTypes : league === 'G-League' ? glgGameTypes : amGameTypes

const SelectGameType = ({ selectedTypes, onGameTypeChange, league }: SelectGameTypeProps): JSX.Element => (
    <FormControl sx={{ width: { xs: '100%', sm: '200px' } }} size="small">
        <InputLabel>Game Type</InputLabel>
        <Select
            name="GameTypes"
            value={selectedTypes}
            label="Game Type"
            sx={{ backgroundColor: 'common.white' }}
            fullWidth
            multiple
            onChange={onGameTypeChange}
        >
            {getLeagueGameTypes(league).map((s) => (
                <MenuItem value={s.value} key={s.value}>
                    {s.label}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
)

export default SelectGameType
