import type { SelectProps } from '@mui/material/Select'
import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { conferenceAbbr, ncaaConferenes } from '@/lib/utils/teams'

type SelectNCAAConferenceProps = {
    onChange: SelectProps['onChange']
    value: Enum.NCAAConference | 'POWER'
    conferenceOptions?: Enum.NCAAConference[]
    height?: number
    disabled?: boolean
}

const SelectNCAAConference = ({
    onChange,
    value,
    conferenceOptions,
    height,
    disabled,
}: SelectNCAAConferenceProps): JSX.Element => (
    <FormControl fullWidth size="small" disabled={disabled}>
        <InputLabel id="conf-label">Conference</InputLabel>
        <Select
            label="Conference"
            name="Conference"
            onChange={onChange}
            value={value}
            multiple={false}
            MenuProps={{ style: { maxHeight: 400 } }}
            sx={{
                fontStyle: value === 'POWER' ? 'italic' : 'normal',
                height,
            }}
        >
            <MenuItem sx={{ fontStyle: 'italic' }} value="POWER">
                Power Conferences
            </MenuItem>
            {(conferenceOptions || ncaaConferenes).map((l) => (
                <MenuItem key={l} value={l}>
                    {l in conferenceAbbr ? conferenceAbbr[l] : l}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
)

export default SelectNCAAConference
