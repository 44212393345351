import type { SelectProps } from '@mui/material/Select'
import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

type SelectLeagueProps = {
    onChange: SelectProps<Enum.League | string[] | 'all'>['onChange']
    value: string[] | Enum.League | 'all' | undefined
    leagueOptions?: Enum.League[]
    height?: string
    multiple?: boolean
    disabled?: boolean
    showAll?: boolean
}

const leagues: Enum.League[] = ['International', 'College', 'NBA', 'G-League', 'Summer', 'OTE', 'FIBA', 'Prep']

const SelectLeague = ({
    onChange,
    value,
    leagueOptions,
    height,
    disabled,
    multiple = true,
    showAll = true,
}: SelectLeagueProps): JSX.Element => (
    <FormControl fullWidth size="small" disabled={disabled}>
        <InputLabel id="league-label">{multiple ? 'League(s)' : 'League'}</InputLabel>

        <Select
            label={multiple ? 'League(s)' : 'League'}
            name="league"
            onChange={onChange}
            value={value || ''}
            multiple={multiple}
            sx={{ fontStyle: value === 'all' ? 'italic' : undefined, height }}
        >
            {!multiple && showAll && (
                <MenuItem sx={{ fontStyle: 'italic', height }} value="all">
                    All
                </MenuItem>
            )}
            {(leagueOptions || leagues).map((l) => (
                <MenuItem key={l} value={l}>
                    {l === 'Summer' ? 'NBA Summer League' : l}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
)

export default SelectLeague
