import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import React from 'react'
import { formatDateString } from '@/lib/utils/formatters'

type ScheduleResultProps = {
    awayTeamAbbr: string
    awayTeamScore: number | null
    homeTeamAbbr: string
    homeTeamScore: number | null
    isHomeTeamWin: boolean | null
    gameStatusText: string | null
    gameStatus: Enum.GameStatus
    gameTimeUTC: Date | null
    gameId: string
    setSelectedGameId: (gameId: string) => void
}

const ScheduleResult = ({
    awayTeamAbbr,
    awayTeamScore,
    homeTeamAbbr,
    homeTeamScore,
    isHomeTeamWin,
    gameStatusText,
    gameTimeUTC,
    gameStatus,
    gameId,
    setSelectedGameId,
}: ScheduleResultProps): JSX.Element => (
    <Box>
        {gameStatus === 'SCHEDULED' && gameTimeUTC ? `${formatDateString(gameTimeUTC, 'h:mm A', 'eastern')} ET` : ''}
        {gameStatus !== 'SCHEDULED' && awayTeamScore && homeTeamScore ? (
            <Button
                sx={{ textTransform: 'none', paddingY: '2px', margin: 0 }}
                variant="text"
                onClick={() => setSelectedGameId(gameId)}
            >
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 0.5 }}>
                    <Box
                        sx={{
                            fontWeight: isHomeTeamWin ? 'normal' : 'bold',
                            color: 'primary.main',
                        }}
                    >
                        {awayTeamAbbr} {awayTeamScore}
                    </Box>
                    <Box>{' - '}</Box>
                    <Box
                        sx={{
                            fontWeight: isHomeTeamWin ? 'bold' : 'normal',
                            color: 'primary.main',
                        }}
                    >
                        {homeTeamAbbr} {homeTeamScore}
                    </Box>
                    {gameStatus === 'IN_PROGRESS' && gameStatusText ? <Box>{gameStatusText}</Box> : null}
                </Box>
            </Button>
        ) : null}
        {gameStatus !== 'SCHEDULED' && (!homeTeamScore || !awayTeamScore) && gameStatusText ? gameStatusText : null}
    </Box>
)

export default ScheduleResult
